<template>
  <Header :title="t('title')" icon="fa-list" />
  <Content>
    <div class=" absolute top-0 h-1/2 w-full">
      <div
        :class="[isEmployee() ? 'mt-12 md:mt-14' : '']"
        class="flex w-full px-6 justify-center -mb-6"
      >
        <SelectorEmployee
          class="w-3/4 mt-0 md:mt-3 lg:w-2/4 xl:w-1/4"
          v-if="isOwner() || isLeader()"
          v-model="selectedEmployee"
        />
      </div>
      <div class="w-11/12 h-44 md:h-auto  mx-auto relative">
        <button
          @click="showReport = !showReport"
          class="text-green text-sm font-semibold tracking-wider absolute right-3 flex px-1 mt-6 border-2 border-green rounded-md"
        >
          <span class="mr-3">
            <i class="fas fa-eye"></i>
          </span>
          <p>{{ !showReport ? t("seeReport") : t("seeGraph") }}</p>
        </button>
        <div
          v-if="
            !showReport &&
              selectedEmployee !== undefinied &&
              selectedEmployee !== '*'
          "
          class="h-44 flex justify-center items-center md:h-72 bg-green-darkest rounded-md pt-3 md:pt-0 px-1 md:px-4 shadow-xl border-2 border-green-dark"
        >
          <p v-if="chartLoaded !== true" class="text-green">
            Der er ingen registreringer i perioden.
          </p>
          <ChartC v-if="chartLoaded === true" :xData="dates" :yData="times" />
        </div>
        <div
          v-if="
            showReport &&
              selectedEmployee !== undefinied &&
              selectedEmployee !== '*'
          "
          class="flex flex-col pt-6 bg-green-darkest rounded-md px-4 shadow-xl border-2 border-green-dark"
        >
          <p class="text-green md:text-lg mb-1">Rapport</p>
          <span class="flex justify-between items-center">
            <p class="text-white text-sm md:text-base">{{ t("workDone") }}</p>
            <p class="text-yellow flex md:tracking-widest text-base md:text-lg">
              {{ Math.floor(totalHours) }}
              <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
              {{ Math.round((totalHours % 1) * 60) }}
              <span class="ml-1 text-green">m</span>
            </p>
          </span>
          <span
            v-if="contract && contract.value > 0"
            class="flex justify-between items-center"
          >
            <p class="text-white text-sm md:text-base">
              {{ t("goalHours") }}
              <span class="text-green text-sm ml-1">{{
                `${contract ? contract.value : 0} * 52 / 12`
              }}</span>
            </p>
            <p class="text-green flex md:tracking-widest text-base md:text-lg">
              {{ Math.floor(goalHours) }}
              <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
              {{ Math.round((goalHours % 1) * 60) }}
              <span class="ml-1 text-green">m</span>
            </p>
          </span>
          <template v-if="contract">
            <span
              v-if="contract.value > 0"
              class="flex justify-between items-center border-t-2 border-green "
            >
              <p
                class="text-white text-sm md:text-base flex flex-col justify-center"
              >
                {{ totalHours - goalHours > 0 ? t("overtime") : t("workLeft") }}
                <span class="text-green text-sm block">{{
                  `( ${Math.floor(totalHours)} t ${Math.round(
                    (totalHours % 1) * 60
                  )} m - ${Math.floor(goalHours)} t ${Math.round(
                    (goalHours % 1) * 60
                  )} m )`
                }}</span>
              </p>
              <p
                class=" flex md:tracking-widest text-base md:text-lg"
                :class="[
                  totalHours - goalHours > 0
                    ? 'text-red-500'
                    : 'text-green-light'
                ]"
              >
                {{
                  totalHours - goalHours > 0
                    ? Math.floor(totalHours - goalHours)
                    : -Math.ceil(totalHours - goalHours)
                }}
                <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
                {{
                  totalHours - goalHours > 0
                    ? Math.round(((totalHours - goalHours) % 1) * 60)
                    : -Math.round(((totalHours - goalHours) % -1) * 60)
                }}
                <span class="ml-1 text-green">m</span>
              </p>
            </span>
          </template>
          <span
            v-if="userOvertime !== null && contract.value > 0"
            class="flex flex-col items-end"
          >
            <div class="flex justify-between items-center w-full">
              <p class="text-white text-sm md:text-base">
                {{ t("previousPeriod") }}
              </p>
              <p
                v-if="userOvertime >= 0"
                class="text-red-500 flex md:tracking-widest text-base md:text-lg"
              >
                +
                {{ Math.floor(userOvertime) }}
                <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
                {{ Math.round((userOvertime % 1) * 60) }}
                <span class="ml-1 text-green">m</span>
              </p>
              <p
                v-if="userOvertime < 0"
                class="text-green-light flex md:tracking-widest text-base md:text-lg"
              >
                -
                {{ -Math.ceil(userOvertime) }}
                <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
                {{ -Math.round((userOvertime % -1) * 60) }}
                <span class="ml-1 text-green">m</span>
              </p>
            </div>
            <div v-if="salary" class="flex justify-between items-center w-full">
              <p class="text-white text-sm md:text-base">
                {{ t("overtimePayout") }}
              </p>
              <p
                class="text-green-light flex md:tracking-widest text-base md:text-lg"
              >
                {{ Math.floor(salary.data().overtimePayout) }}
                <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
                {{ Math.round((salary.data().overtimePayout % 1) * 60) }}
                <span class="ml-1 text-green">m</span>
              </p>
            </div>
            <OvertimePayoutButton
              v-if="userOvertime > 0 && isOwner() && !isMobile()"
              :selectedEmployee="selectedEmployee"
              :overtime="
                userOvertime +
                  (totalHours - goalHours > 0 ? totalHours - goalHours : 0)
              "
            />
          </span>
          <span
            v-if="contract.value > 0"
            class="flex flex-col items-end border-t-2 border-green mt-3"
          >
            <div class="flex justify-between items-center w-full">
              <p class="text-white text-sm md:text-base">
                {{ t("total") }}
              </p>
              <p
                v-if="
                  totalHours -
                    goalHours +
                    userOvertime -
                    (salary ? salary.data().overtimePayout : 0) >=
                    0
                "
                class="text-red-500 flex md:tracking-widest text-base md:text-lg"
              >
                +
                {{
                  Math.floor(
                    totalHours -
                      goalHours +
                      userOvertime -
                      (salary ? salary.data().overtimePayout : 0)
                  )
                }}
                <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
                {{
                  Math.round(
                    ((totalHours -
                      goalHours +
                      userOvertime -
                      (salary ? salary.data().overtimePayout : 0)) %
                      1) *
                      60
                  )
                }}
                <span class="ml-1 text-green">m</span>
              </p>
              <p
                v-if="
                  totalHours -
                    goalHours +
                    userOvertime -
                    (salary ? salary.data().overtimePayout : 0) <
                    0
                "
                class="text-green-light flex md:tracking-widest text-base md:text-lg"
              >
                -
                {{
                  -Math.ceil(
                    totalHours -
                      goalHours +
                      userOvertime -
                      (salary ? salary.data().overtimePayout : 0)
                  )
                }}
                <span class="ml-1 text-green mr-3">{{ t("h") }}</span>
                {{
                  -Math.round(
                    ((totalHours -
                      goalHours +
                      userOvertime -
                      (salary ? salary.data().overtimePayout : 0)) %
                      1) *
                      60
                  )
                }}
                <span class="ml-1 text-green">m</span>
              </p>
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="absolute bottom-0 h-1/2 w-full flex flex-col items-center">
      <table
        v-if="
          userTimeRegistrations.length !== 0 &&
            timeRegistrationsInInterval.length === 0 &&
            selectedEmployee !== undefined &&
            selectedEmployee !== '*'
        "
        class="w-full h-full flex flex-col text-white pb-3"
      >
        <div
          class="h-full w-full overflow-y-scroll overflow-x-none grid grid-cols-1 md:grid-cols-3 gap-x-0 gap-y-1 md:gap-x-6 md:gap-y-3"
        >
          <RegistrationItem
            v-for="time in userTimeRegistrations.filter(
              t =>
                t.data().uid === selectedEmployee.data().uid &&
                !t.data().deleted &&
                t.data().end !== false
            )"
            :key="time.data().start"
            :time="time"
          />
        </div>
      </table>

      <table
        v-if="
          timeRegistrationsInInterval.length !== 0 &&
            selectedEmployee !== undefined &&
            selectedEmployee !== '*'
        "
        class="w-full h-full flex flex-col text-white pb-3"
      >
        <div
          class="h-full w-full overflow-y-scroll overflow-x-none grid grid-cols-1 md:grid-cols-3 gap-x-0 gap-y-1 md:gap-x-6 md:gap-y-3"
        >
          <RegistrationItem
            v-for="time in timeRegistrationsInInterval.filter(
              t =>
                t.data().uid === selectedEmployee.data().uid &&
                !t.data().deleted &&
                t.data().end !== false
            )"
            :key="time.data().start"
            :time="time"
          />
        </div>
      </table>
    </div>
  </Content>
</template>

<script>
import Header from "@/components/Header";
import Content from "@/components/Content";
import OvertimePayoutButton from "@/components/TimeRegistration/overtime-payout-button";
import ChartC from "@/components/Chart";
import SelectorEmployee from "@/components/SelectorEmployee";
import RegistrationItem from "@/components/TimeRegistration/RegistrationItem/RegistrationItem";
import { ref, watchEffect } from "vue";
import { isMobile } from "@/global/services/mixins";
import { authentication } from "@/global/services/authentication";
import { units } from "@/global/services/units";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { period } from "@/timeregistration/services/period";
import { farm } from "@/global/services/farm";
import { employee } from "@/global/services/employee";
import { salaries } from "@/timeregistration/services/salaries";

import { useI18n } from "vue-i18n";

export default {
  name: "Registrations",
  components: {
    SelectorEmployee,
    RegistrationItem,
    Header,
    Content,
    ChartC,
    OvertimePayoutButton
  },
  setup() {
    const state = {
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,
      employeeDoc: employee.get(true),
      userOvertime: ref(null),
      contract: ref(null),
      goalHours: ref(0),
      totalHours: ref(0),
      dates: ref([]),
      times: ref([]),
      chartLoaded: ref(false),
      errorCode: ref(""),
      salariesList: salaries.getAll(true),
      salary: ref(undefined),
      showReport: ref(false),

      farmUserList: units.getFarmUsers(true),
      selectedEmployee: ref(undefined),

      timeRegistrationsInInterval: timeRegistration.getTimeRegistrationsInIntervalList(
        true
      ),
      timeRegistrationsInIntervalData: timeRegistration.getTimeRegistrationsInIntervalDataList(
        true
      ),
      userTimeRegistrations: timeRegistration.getUserTimeRegistrationsList(
        true
      ),
      userTimeRegistrationsData: timeRegistration.getUserTimeRegistrationsDataList(
        true
      )
    };

    const { t } = useI18n({
      messages: {
        en: {
          title: "Registrations",
          day: "Day",
          time: "Time",
          duration: "Duration",
          break: "Break",
          workDone: "Work done",
          workLeft: "Work left",
          overtime: "Overtime",
          previousPeriod: "Previous periods",
          overtimePayout: "Overtime paid",
          total: "Total",
          seeReport: "See report",
          seeGraph: "See graph",
          h: "h",
          goalHours: "Goal this period"
        },
        dk: {
          title: "Registreringer",
          day: "Dag",
          time: "Tid",
          duration: "Varighed",
          break: "Pause",
          workDone: "Udført arbejde",
          workLeft: "Arbejde til overs",
          overtime: "Overarbejde",
          previousPeriod: "Forrige perioder",
          overtimePayout: "Udbetalt overarbejde",
          total: "Totalt",
          seeReport: "Se rapport",
          seeGraph: "Se graf",
          h: "t",
          goalHours: "Mål denne periode"
        }
      }
    });

    watchEffect(() => {
      if (
        state.salariesList.value.length !== 0 &&
        state.selectedEmployee.value !== undefined &&
        state.selectedEmployee.value !== "*"
      ) {
        state.salary.value = salaries.getForEmployee(
          state.selectedEmployee.value.id
        );
      } else {
        state.salary.value = undefined;
      }
    });

    watchEffect(() => {
      if (
        state.selectedEmployee.value !== undefined &&
        state.selectedEmployee.value !== "*"
      ) {
        if (state.selectedEmployee.value.data().hours) {
          const reducer = (pre, cur) => {
            if (cur.year > pre.year || pre === undefined) {
              return cur;
            } else if (cur.month > pre.month && cur.year === pre.year) {
              return cur;
            } else if (
              cur.date > pre.date &&
              cur.month === pre.month &&
              cur.year === pre.year
            ) {
              return cur;
            }
          };
          state.contract.value = state.selectedEmployee.value
            .data()
            .hours.reduce(reducer);
          state.goalHours.value = (state.contract.value.value * 52) / 12;
        }
      }
    });

    watchEffect(() => {
      if (authentication.isEmployee() && employee.get()) {
        state.selectedEmployee.value = employee.get();

        if (
          timeRegistration.getUserTimeRegistrationsList().length === 0 &&
          units.getSelectedUnit()
        ) {
          timeRegistration.updateUserTimes();
        }
      } else if (authentication.isOwner() || authentication.isLeader()) {
        if (
          timeRegistration.getTimeRegistrationsInIntervalList().length === 0 &&
          units.getSelectedUnit()
        ) {
          timeRegistration.updateTimeRegistrationsInInterval();
        }
      }
    });

    watchEffect(() => {
      if (
        farm.getMonthReports(true).value.length !== 0 &&
        farm.getMonthReports(true).value.length ===
          farm.getMonthReportsLength() &&
        state.selectedEmployee.value !== undefined &&
        state.selectedEmployee.value !== "*"
      ) {
        const latest = farm.getLatestMonthReport();
        if (latest) {
          if (latest.data()[state.selectedEmployee.value.id]) {
            state.userOvertime.value = farm.getLatestMonthReport().data()[
              state.selectedEmployee.value.id
            ]["sumOvertime"];
          } else {
            state.userOvertime.value = null;
          }
        } else {
          state.userOvertime.value = null;
        }
      }
    });

    function diffTime(reg) {
      const dt1 = new Date(reg.data().start);
      const dt2 = new Date(reg.data().end);
      let diff = dt2.getTime() - dt1.getTime();
      reg.data().breakDuration !== undefined
        ? (diff = diff - reg.data().breakDuration)
        : null;
      const diffMin = Math.round((diff * 100) / 3600000) / 100;
      return diffMin;
    }
    const formatDateChart = time => {
      const date = new Date(parseInt(time));
      date.setHours(0, 0, 0, 1);
      return date.toISOString();
    };

    watchEffect(() => {
      const periodStart = new Date(period.start.value);
      const periodEnd = new Date(period.end.value);
      salaries.downloadPeriodSalaries(periodStart, periodEnd);
    });

    watchEffect(() => {
      if (
        state.selectedEmployee.value !== undefined &&
        state.selectedEmployee.value !== "*" &&
        (state.timeRegistrationsInInterval.value.length !== 0 ||
          state.userTimeRegistrations.value.length !== 0)
      ) {
        state.chartLoaded.value = false;
        state.dates.value = [];
        state.times.value = [];
        let totalTime = 0;
        const userTimes =
          state.timeRegistrationsInInterval.value.length !== 0
            ? state.timeRegistrationsInInterval.value.filter(
                t =>
                  t.data().uid === state.selectedEmployee.value.data().uid &&
                  !t.data().deleted &&
                  t.data().end !== false
              )
            : state.userTimeRegistrations.value.filter(
                t =>
                  t.data().uid === state.selectedEmployee.value.data().uid &&
                  !t.data().deleted &&
                  t.data().end !== false
              );
        const userTimesFiltered = userTimes.filter(t => t.data().end !== false);
        if (userTimesFiltered.length !== 0) {
          state.dates.value = period.getPeriodDateList();
          state.dates.value.forEach(() => {
            state.times.value.push(0);
          });

          userTimesFiltered.forEach(e => {
            const idx = state.dates.value.indexOf(
              formatDateChart(e.data().start)
            );
            const diff = diffTime(e);
            state.times.value[idx] += diff;
            totalTime += diff;
          });
          state.totalHours.value = totalTime;
          state.chartLoaded.value = true;
        } else {
          state.totalHours.value = 0;
        }
      } else {
        state.totalHours.value = 0;
        state.chartLoaded.value = false;
      }
    });

    return {
      t,
      ...state,
      isMobile
    };
  }
};
</script>
