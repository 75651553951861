import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { authentication } from "@/global/services/authentication";
import { errorLogging } from "@/global/services/errorLogging";
import { period } from "@/timeregistration/services/period";
import { ref, watchEffect } from "vue";
import { employee } from "../../global/services/employee";

let salariesDetacher: any = undefined;
const salaryList = ref([] as Array<firebase.firestore.DocumentSnapshot>);

export const salaries = {
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  downloadPeriodSalaries: function(
    periodStart: Date,
    periodEnd: Date
  ): Promise<number | string> {
    salaryList.value = [];
    salariesDetacher !== undefined ? salariesDetacher() : null;

    const fbStartTimeStamp = firebase.firestore.Timestamp.fromDate(periodStart);
    const fbEndTimeStamp = firebase.firestore.Timestamp.fromDate(periodEnd);

    return new Promise((resolve, reject) => {
      const salariesCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/salaries")
        .where("deleted", "==", null)
        .where("periodEnd", ">=", fbStartTimeStamp)
        .where("periodEnd", "<=", fbEndTimeStamp);
      salariesDetacher = salariesCollectionRef.onSnapshot((querySnap: any) => {
        console.log(
          `Received query snapshot for salaries: size ${querySnap.size}`
        );
        resolve(querySnap.size);
        querySnap.docChanges().forEach(
          (docChange: any) => {
            const docReference = docChange.doc;

            if (docChange.type === "added") {
              salaryList.value.push(docReference);
            }
            if (docChange.type === "modified") {
              const idx = salaryList.value.findIndex(
                (x: any) => x.id === docReference.id
              );
              salaryList.value.splice(idx, 1, docReference);
            }

            if (docChange.type === "removed") {
              const idx = salaryList.value.findIndex(
                (x: any) => x.id === docReference.id
              );
              salaryList.value.splice(idx, 1);
            }
          },
          (error: any) => reject(error.message)
        );
      });
    });
  },

  getAll: function(reference = false) {
    if (reference) {
      return salaryList;
    } else return salaryList.value;
  },

  getForEmployee: function(employeeId: string) {
    let doc = undefined;
    if (salaryList.value.length !== 0) {
      doc = salaryList.value.find(s => {
        const data = s.data();
        if (data) {
          console.log("Doc data", data);
          return employeeId === data.eid;
        } else {
          return false;
        }
      });
      return doc;
    } else {
      return doc;
    }
  },

  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  add: function(data: any): Promise<void | string> {
    return new Promise((resolve, reject) => {
      const dataCopy = Object.assign({}, data);
      dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();
      dataCopy.createdBy = employee.getId();
      const periodEnd = new Date(period.end.value);
      dataCopy.periodEnd = firebase.firestore.Timestamp.fromDate(periodEnd);
      dataCopy.deleted = null;
      firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/salaries")
        .add(dataCopy)
        .then(() => resolve())
        .catch(e => reject(e.message));
    });
  },

  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  update: function(doc: any, data: any): Promise<void | string> {
    return new Promise((resolve, reject) => {
      const dataCopy = Object.assign({}, data);
      dataCopy.updated = firebase.firestore.FieldValue.serverTimestamp();
      dataCopy.updatedBy = employee.getId();
      doc.ref.update(dataCopy).then(() => {
        resolve();
      });
    });
  }
};
