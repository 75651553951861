<template>
  <div
    @click="openTimeChangeModal(time)"
    :class="[data.note ? 'grid-rows-3' : 'grid-rows-2']"
    class="relative bg-green-darkest w-full grid grid-cols-3 hover:bg-green-dark cursor-pointer"
  >
    <span
      v-if="history.length !== 0"
      class="text-green flex justify-center items-center absolute left-3 top-1/3 "
    >
      <i class="fas fa-history"></i>
    </span>
    <div class=" col-span-1 row-span-1 flex items-center justify-center">
      <span>{{ formatDate(data.start) }}</span>
    </div>
    <div class=" col-span-1 row-span-2 flex justify-center items-center">
      <p class="text-xl">
        {{ formatDuration(data.start, data.end, data.breakDuration) }}
      </p>
    </div>
    <div class="col-span-1 row-span-1 flex items-center justify-center">
      <span v-if="data.breakDuration">
        <p class="text-green text-sm">
          Pause {{ formatBreak(data.breakDuration) }}
        </p>
      </span>
    </div>
    <div class="col-span-1 row-span-1 flex items-center justify-center">
      <span class="text-green text-sm">{{ formatDay(data.start) }}</span>
    </div>

    <div class=" col-span-1 row-span-1 flex items-center justify-center">
      <span class="">
        <p class="text-green text-sm">
          {{ formatTime(data.start) }} <span class="mx-1">-</span
          >{{ formatTime(data.end) }}
        </p>
      </span>
    </div>
    <div v-if="data.note && data.note !== ''" class=" col-span-3 row-span-1">
      <p class="py-1 px-3 border-t-2 border-green-dark">{{ data.note }}</p>
    </div>
  </div>

  <Modal @closeModal="modalTime = null" :visible="modalTime !== null">
    <div v-if="!showHistory" class="w-full grid grid-cols-2 gap-y-3 gap-x-3">
      <div class="col-span-2 flex items-center">
        <span
          class="flex flex-shrink-0 justify-center items-center bg-green rounded-full w-10 h-10 z-10 mr-3"
        >
          <i class="fas fa-edit"></i>
        </span>
        <p class="text-lg font-semibold text-center">
          Rediger registreringen her.
        </p>
      </div>
      <div class="col-span-1 grid grid-rows-3">
        <p class="font-semibold text-green row-span-1 ">Start:</p>
        <DatePicker
          v-model="modalTimeData.start"
          class="text-green-darkest bg-white w-full row-span-1"
        />
        <p></p>
      </div>
      <div class="col-span-1">
        <TimePickerNew v-model="modalTimeData.start" class="w-full" />
      </div>
      <div class="col-span-1 grid grid-rows-3">
        <p class="font-semibold text-green row-span-1">Slut:</p>
        <DatePicker
          v-model="modalTimeData.end"
          class="text-green-darkest bg-white w-full row-span-1"
        />
      </div>
      <div class="col-span-1">
        <TimePickerNew v-model="modalTimeData.end" class="w-full" />
      </div>
      <div class="col-span-1 flex items-center">
        <p class="font-semibold text-green">Pause:</p>
      </div>
      <div class="col-span-1">
        <TimePickerNew
          utc="true"
          duration="true"
          v-model="modalTimeData.breakDuration"
          class="w-full"
        />
      </div>
      <div class="col-span-2">
        <p class="font-semibold text-green">Kommentar:</p>
        <Textarea v-model="modalTimeData.note" />
      </div>
      <div class="col-span-2 flex justify-between">
        <DeleteButton v-if="data.uid === uid" :time="time" />
        <button
          v-if="history.length !== 0"
          @click="showHistory = !showHistory"
          class="text-green border-b-2 border-green flex rounded-md shadow-md px-3 py-2 font-semibold md:tracking-wider"
        >
          <span class="md:mr-2 w-8 -ml-3">
            <i class="fas fa-history"></i>
          </span>
          <p>Vis historik</p>
        </button>
      </div>
      <Button
        v-if="modalTimeData !== data"
        class=" mt-3 col-span-2 bg-gradient-to-r from-yellow-dark to-yellow hover:from-yellow hover:to-yellow-dark"
        :label="
          hasDurationIncreased() && isEmployee()
            ? 'Send anmodning'
            : 'Gem ændringer'
        "
        @click="submitTimeChange()"
      />
    </div>
    <div v-if="showHistory && history.length !== 0" class="w-full">
      <div class="col-span-3 row-span-1 text-center text-green text-lg">
        <p>Nuværende registrering</p>
      </div>
      <div
        :class="[data.note ? 'grid-rows-3' : 'grid-rows-2']"
        class="bg-gradient-to-r from-green-darkest via-green to-green-darkest w-full grid grid-cols-3 py-3"
      >
        <div class=" col-span-1 row-span-1 flex items-center justify-center">
          <span>{{ formatDate(data.start) }}</span>
        </div>
        <div class=" col-span-1 row-span-2 flex justify-center items-center">
          <p class="text-xl">
            {{ formatDuration(data.start, data.end, data.breakDuration) }}
          </p>
        </div>
        <div class="col-span-1 row-span-1 flex items-center justify-center">
          <span class="">
            <p class="text-sm">Pause {{ formatBreak(data.breakDuration) }}</p>
          </span>
        </div>
        <div class="col-span-1 row-span-1 flex items-center justify-center">
          <span class="text-sm">{{ formatDay(data.start) }}</span>
        </div>

        <div class=" col-span-1 row-span-1 flex items-center justify-center">
          <span class="">
            <p class="text-sm">
              {{ formatTime(data.start) }} <span class="mx-1">-</span
              >{{ formatTime(data.end) }}
            </p>
          </span>
        </div>
        <div
          v-if="data.note && data.note !== ''"
          class=" col-span-3 row-span-1"
        >
          <p class="py-1 px-3 border-t-2 border-green-dark">{{ data.note }}</p>
        </div>
      </div>
      <div class="mt-3 w-full h-64 overflow-y-scroll overflow-x-none">
        <div
          v-for="r in history"
          :key="r.before.start"
          class="bg-green-darkest w-full hover:bg-green-dark border-t border-green-dark"
        >
          <div class="text-center text-green w-full mt-3">
            <p>Den {{ formatDate(r.created * 1000) }}</p>
          </div>
          <div
            :class="[r.before.note ? 'grid-rows-3' : 'grid-rows-2']"
            class="grid grid-cols-3"
          >
            <div
              class=" col-span-1 row-span-1 flex items-center justify-center"
            >
              <span>{{ formatDate(r.before.start) }}</span>
            </div>
            <div
              class=" col-span-1 row-span-2 flex justify-center items-center"
            >
              <p class="text-xl">
                {{
                  formatDuration(
                    r.before.start,
                    r.before.end,
                    r.before.breakDuration
                  )
                }}
              </p>
            </div>
            <div class="col-span-1 row-span-1 flex items-center justify-center">
              <span class="">
                <p class="text-green text-sm">
                  Pause {{ formatBreak(r.before.breakDuration) }}
                </p>
              </span>
            </div>
            <div class="col-span-1 row-span-1 flex items-center justify-center">
              <span class="text-green text-sm">{{
                formatDay(r.before.start)
              }}</span>
            </div>

            <div
              class=" col-span-1 row-span-1 flex items-center justify-center"
            >
              <span class="">
                <p class="text-green text-sm">
                  {{ formatTime(r.before.start) }} <span class="mx-1">-</span
                  >{{ formatTime(r.before.end) }}
                </p>
              </span>
            </div>
            <div
              v-if="r.before.note && r.before.note !== ''"
              class=" col-span-3 row-span-1"
            >
              <p class="py-1 px-3 border-t-2 border-green-dark">
                {{ r.before.note }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex justify-center">
        <button
          v-if="history.length !== 0"
          @click="showHistory = !showHistory"
          class="text-yellow border-b-2 border-yellow flex rounded-md shadow-md px-3 py-2 font-semibold md:tracking-wider"
        >
          <span class="md:mr-2 w-8 -ml-3">
            <i class="fas fa-angle-left"></i>
          </span>
          <p>Luk historik</p>
        </button>
      </div>
    </div>
    <!-- 
      <MapButton v-if="data.startGps !== undefined" :time="time" /> -->
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import DatePicker from "@/components/DatePicker";
import TimePickerNew from "@/components/TimePickerNew";
import Textarea from "@/components/Textarea";
import DeleteButton from "./DeleteButton";
import { ref, computed, watch } from "vue";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";
import { authentication } from "@/global/services/authentication";
import { systemState } from "@/global/services/systemState";
import { errorLogging } from "@/global/services/errorLogging";
import { requests } from "@/global/services/requests";

import { useI18n } from "vue-i18n";

export default {
  name: "RegistrationItem",
  components: {
    Modal,
    Button,
    DatePicker,
    TimePickerNew,
    Textarea,
    DeleteButton
  },
  props: {
    time: Object
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          monday: "Mon",
          tuesday: "Tue",
          wednesday: "Wed",
          thursday: "Thu",
          friday: "Fri",
          saturday: "Sat",
          sunday: "Sun"
        },
        dk: {
          monday: "Man",
          tuesday: "Tir",
          wednesday: "Ons",
          thursday: "Tor",
          friday: "Fre",
          saturday: "Lør",
          sunday: "Søn"
        }
      }
    });
    const state = {
      isOwner: authentication.isOwner,
      isLeader: authentication.isLeader,
      isEmployee: authentication.isEmployee,
      uid: ref(authentication.getUserUid()),

      data: computed(() => props.time.data()),
      showHistory: ref(false),
      history: ref([]),

      modalTime: ref(null),
      modalTimeData: ref(null)
    };

    watch(
      state.data.value,
      () => {
        timeRegistration.getRegistrationHistory(props.time.id).then(list => {
          if (list.length !== 0) state.history.value = list;
        });
      },
      { immediate: true }
    );

    const functions = {
      hasDurationIncreased: function() {
        const date1 = new Date(state.data.value.end);
        const date2 = new Date(state.data.value.start);
        let diff = date2.getTime() - date1.getTime();
        state.data.value.breakDuration !== undefined
          ? (diff = diff - state.data.value.breakDuration)
          : null;

        const date3 = new Date(state.modalTimeData.value.end);
        const date4 = new Date(state.modalTimeData.value.start);
        let diff2 = date4.getTime() - date3.getTime();
        state.modalTimeData.value.breakDuration !== undefined
          ? (diff2 = diff2 - state.modalTimeData.value.breakDuration)
          : null;

        return diff2 < diff;
      },
      formatDuration: function(end, start, breakDuration) {
        const date1 = new Date(parseInt(end));
        const date2 = new Date(parseInt(start));
        let diff = date2.getTime() - date1.getTime();
        breakDuration !== undefined ? (diff = diff - breakDuration) : null;
        const diffMin = diff / (60 * 1000);
        let minutes = Math.round(diffMin % 60).toString();
        minutes.length === 1 ? (minutes = "0" + minutes) : null;
        const hours = Math.floor(diffMin / 60);
        return `${hours} : ${minutes}`;
      },
      formatTime: function(time) {
        if (time === undefined) return "n/a";
        const date = new Date(parseInt(time));
        let minutes = date.getMinutes().toString();
        minutes.length === 1 ? (minutes = "0" + minutes) : null;
        const string = `${date.getHours()} : ${minutes}`;
        return string;
      },
      formatBreak: function(time) {
        if (time === undefined) return "";
        let minutes = time % (1000 * 60 * 60);
        minutes = minutes / (1000 * 60);
        minutes < 10 ? (minutes = "0" + minutes.toString()) : null;
        const hours = time / (1000 * 60 * 60);
        const string = `${Math.floor(hours)} : ${minutes}`;
        return string;
      },
      formatDate: function(time) {
        const date = new Date(parseInt(time));
        const string = `${date.getDate()} - ${date.getMonth() + 1}`;
        return string;
      },

      formatDay: function(time) {
        const days = [
          t("sunday"),
          t("monday"),
          t("tuesday"),
          t("wednesday"),
          t("thursday"),
          t("friday"),
          t("saturday")
        ];
        const date = new Date(parseInt(time));
        const string = `${days[date.getUTCDay()]}`;
        return string;
      },
      openTimeChangeModal: function() {
        state.modalTime.value = props.time;
        state.modalTimeData.value = Object.assign({}, props.time.data());
      },

      submitTimeChange: async function() {
        systemState.startProcessing();

        console.log("Duration increased: ", functions.hasDurationIncreased());

        if (state.modalTimeData.value.end < state.modalTimeData.value.start) {
          errorLogging.setWarning("Slut tiden kan ikke være før start tiden.");

          state.modalTimeData.value.end = state.modalTimeData.value.start;

          systemState.stopProcessing();
        } else if (
          await timeRegistration.checkTimeExists(
            state.modalTimeData.value,
            props.time.id
          )
        ) {
          errorLogging.setWarning(
            "Du har allerede registreret tid i dette tidsrum."
          );

          systemState.stopProcessing();
        } else {
          if (functions.hasDurationIncreased() && state.isEmployee()) {
            requests
              .createRequest(
                props.time.id,
                state.modalTimeData.value,
                state.data.value,
                "TimeUpdate"
              )
              .then(message => {
                state.modalTime.value = null;
                systemState.stopProcessing();
                errorLogging.setSuccess(message);
              })
              .catch(message => {
                errorLogging.setError(message);
                systemState.stopProcessing();
              });
          } else {
            timeRegistration
              .changeTimeRegistration(
                state.modalTime.value,
                state.modalTimeData.value
              )
              .then(message => {
                state.modalTime.value = null;
                systemState.stopProcessing();
                errorLogging.setSuccess(message);
              })
              .catch(message => {
                errorLogging.setError(message, "Line 331", "Registration Item");
                systemState.stopProcessing();
              });
          }
        }
      }
    };

    return { ...state, ...functions };
  }
};
</script>

<style scoped></style>
