<template>
  <Button
    :label="
      numberOfHours <= 0
        ? t(`overtimePayoutButton.buttonLabel1`)
        : t(`overtimePayoutButton.buttonLabel2`)
    "
    class=" bg-yellow"
    @click="openModal()"
  />
  <Modal @closeModal="showModal = !showModal" :visible="showModal">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center text-xl bg-green-dark rounded-full w-14 h-14 z-10 mb-3 text-white"
    >
      <i class="fas fa-money-bill"></i>
    </span>

    <p>{{ t("message") }}</p>
    <Input
      class="my-6"
      inputWidth="w-20"
      :label="t('label1')"
      type="number"
      v-model="numberOfHours"
    />
    <Button
      class="bg-yellow hover:bg-green"
      :label="t('confirm')"
      @click="submitNewRequest()"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import Input from "@/components/Input";
import { errorLogging } from "@/global/services/errorLogging";
import { salaries } from "@/timeregistration/services/salaries";
import { systemState } from "@/global/services/systemState";
import { ref, watchEffect } from "vue";

import { useI18n } from "vue-i18n";

export default {
  name: "OvertimePayoutButton",
  components: {
    Button,
    Modal,
    Input
  },
  props: {
    selectedEmployee: Object,
    overtime: Number
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          message: "Pay out overtime",
          label1: "Number of hours",
          confirm: "Confirm"
        },
        dk: {
          message: "Udbetal overarbejde",
          label1: "Antal timer",
          confirm: "Godkend"
        }
      }
    });

    const state = {
      showModal: ref(false),
      numberOfHours: ref(0)
    };

    watchEffect(() => {
      const salaryDoc = salaries.getForEmployee(props.selectedEmployee.id);
      if (salaryDoc) {
        state.numberOfHours.value = salaryDoc.data().overtimePayout;
      } else {
        state.numberOfHours.value = 0;
      }
    });

    const functions = {
      openModal: () => {
        state.showModal.value = !state.showModal.value;
      },
      submitNewRequest: () => {
        systemState.startProcessing();
        if (props.overtime < state.numberOfHours.value) {
          systemState.stopProcessing();
          errorLogging.setWarning(
            "Du kan ikke udbetale flere timer end der er overarbejde"
          );
        } else {
          const data = {
            overtimePayout: state.numberOfHours.value,
            eid: props.selectedEmployee.id
          };
          console.log("Emp id", props.selectedEmployee.id);
          const salaryDoc = salaries.getForEmployee(props.selectedEmployee.id);
          if (salaryDoc) {
            console.log(salaryDoc.data());
            salaries
              .update(salaryDoc, data)
              .then(message => {
                systemState.stopProcessing();
                state.showModal.value = false;
                errorLogging.setSuccess(message);
              })
              .catch(message => {
                errorLogging.setError(message);
                systemState.stopProcessing();
              });
          } else {
            salaries
              .add(data)
              .then(message => {
                systemState.stopProcessing();
                state.showModal.value = false;
                errorLogging.setSuccess(message);
              })
              .catch(message => {
                errorLogging.setError(message);
                systemState.stopProcessing();
              });
          }
        }
      }
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
