<template>
  <Button
    class="col-span-1 border-b-2 border-red-500 flex text-red-500"
    icon="fa-trash-alt"
    label="Slet registrering"
    @click="openModal()"
  />
  <Modal @closeModal="closeModal()" :visible="showModal">
    <span
      class=" flex-col flex flex-shrink-0 justify-center items-center bg-green rounded-full w-10 h-10 z-10 mb-3 text-white"
    >
      <i class="fas fa-trash-alt"></i>
    </span>
    <p class="text-lg font-semibold my-3 text-center">
      {{ t("desc1") }} <span class="text-red-500">{{ t("desc2") }}</span>
      {{ t("desc3") }}
    </p>
    <Button
      class="bg-red-500 hover:bg-red-600"
      :label="t('accept')"
      @click="deleteTimeRegistration()"
    />
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import { ref } from "vue";
import { timeRegistration } from "@/timeregistration/services/timeRegistration";

import { useI18n } from "vue-i18n";

export default {
  name: "DeleteButton",
  components: {
    Modal,
    Button
  },
  props: {
    time: Object
  },
  setup(props) {
    const { t } = useI18n({
      messages: {
        en: {
          desc1: "Confirm",
          desc2: "deleting",
          desc3: "this registration.",
          accept: "Confirm"
        },
        dk: {
          desc1: "Bekræft at du vil",
          desc2: "slette",
          desc3: "denne registrering.",
          accept: "Godkend"
        }
      }
    });
    const state = {
      showModal: ref(false)
    };

    const functions = {
      openModal: function() {
        state.showModal.value = true;
      },
      closeModal: function() {
        state.showModal.value = false;
      },

      deleteTimeRegistration: () =>
        timeRegistration.deleteTimeRegistration(props.time.id)
    };

    return { t, ...state, ...functions };
  }
};
</script>

<style scoped></style>
